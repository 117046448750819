(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/branding/assets/javascripts/branding.js') >= 0) return;  svs.modules.push('/components/lb-utils/branding/assets/javascripts/branding.js');
"use strict";


const branding = () => {
  var _svs$core$config$data;
  if (((_svs$core$config$data = svs.core.config.data.svsconfig.site) === null || _svs$core$config$data === void 0 ? void 0 : _svs$core$config$data.toLowerCase()) === 'momang') {
    return 'MOMANG';
  }
  return 'SPORT';
};
setGlobal('svs.components.lbUtils.branding', branding());

 })(window);